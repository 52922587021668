<template>
  <router-view />

  <section id="contact" class="contact">
    <div class="container">
      <div class="row m-b-lg" style="margin: 40px auto 0; margin-bottom: 40px">
        <div class="col-lg-4 col-lg-offset-1">
          <p><img src="img/logo_white.svg" height="25px" /></p>
          <p><strong>CRM система для салонов штор и текстильных декораторов</strong></p>
          <p><strong>ООО "Курс24"</strong></p>
          <p><strong>&copy; curs24.online, 2022</strong></p>
        </div>
        <div class="col-lg-3">
          <h3>Информация</h3>
          <p style="margin-top: 5px; margin-bottom: 5px">
            <strong>
              <router-link to="/payment">
                Вариант оплаты
              </router-link>
            </strong>
            <br />
            <strong>
              <a href="/files/Offerta_CURS24.pdf" target="_blank" rel="noopener noreferrer">Договор оферты</a>
            </strong>
          </p>
          <img src="img/cdek.png" style="height:40px" />
          <img src="img/sbp.png" style="height:40px"/>
          <!-- <p style="margin-top: 5px; margin-bottom: 5px">
            <strong>Договор оферты</strong>
          </p> -->
        </div>
        <div class="col-lg-3">
          <h3>Контакты</h3>
          <p style="margin-top: 5px; margin-bottom: 5px">
            <strong>Звонки, WhatsApp, Telegram</strong>
          </p>
          <h3><a href="tel:+79151540024">+7 (915) 154-00-24</a></h3>

          <h3 style="margin-top: 10px">
            <a href="mailto:info@curs24.online">info@curs24.online</a>
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>

<style></style>
