import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

function visibleMenu(to) {
  var divsToHide = document.getElementsByClassName("home-menu");
  const displayValue = to.fullPath === '/'?'flex':'none';
  for(var i = 0; i < divsToHide.length; i++){
    divsToHide[i].style.display = displayValue;
  }
  return true;
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: [visibleMenu],
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/PaymentView.vue'),
    beforeEnter: [visibleMenu],
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({behavior:'smooth'});
 }
})

export default router
