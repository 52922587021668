<template>
  <div id="inSlider" class="carousel carousel-fade" data-ride="carousel">
    <div class="carousel-inner" role="listbox">
      <div class="item active">
        <div class="container">
          <div class="carousel-caption blank">
            <h1>
              Онлайн-сервис для <br />
              управления салоном штор
            </h1>
            <p style="text-transform: none; font-weight: 300; font-size: 20px">
              Наведите порядок в бизнесе и начните зарабатывать больше <br />
              с первых дней использования CURS24
            </p>
            <p style="text-transform: none">
              <a class="btn btn-green large-button mr-2 btn-consultation" btn-type="Консультация вверху лендинга" href="#"
                style="margin-right: 10px" role="button">Нужна консультация</a>
              <a class="btn btn-primary large-button btn-register" btn-type="Заявка на подключение" href="#"
                role="button" style="margin-right: 10px">Заявка на подключение</a>
            </p>
            <h3 style="margin-top:10px;"><a style="color:white; padding-top: 10px" href="https://t.me/curs24"><i class="fa fa-send"></i>Телеграмм канал @CURS24</a></h3>            
          </div>
          <div class="carousel-image wow zoomIn">
            <img height="380" src="img/laptop.svg" alt="laptop" />
          </div>
        </div>
        <!-- Set background for slide in css -->
        <div class="header-back one"></div>
      </div>
    </div>
  </div>

  <section id="system" class="container services">
    <div class="row">
      <div class="col-lg-12 text-center">
        <div class="navy-line"></div>
        <h1>Что такое CURS24?</h1>
        <h1>
          <span class="navy">Облачная CRM система для салонов штор и текстильных декораторов</span>
        </h1>
      </div>
    </div>

    <div class="row padding-top-15 text-center">
      <div class="col-md-6 wow fadeIn d-flex">
        <img height="200" src="img/auditory.svg" />
        <section class="wrapper-1">
          <div class="box">
            <h2 class="text-center">Кому подходит?</h2>
            <ul class="text-left lead">
              <li>
                <span>Салонам штор без ограничений по количеству сотрудников и
                  салонов.</span>
              </li>
              <li><span>Частным текстильным дизайнерам.</span></li>
              <li><span>Швейным производствам.</span></li>
              <li><span>Поставщикам тканей и фурнитуры.</span></li>
            </ul>
          </div>
        </section>
      </div>
      <div class="col-md-6 wow fadeIn d-flex">
        <img height="200" src="img/problem.svg" />
        <section class="wrapper-1">
          <div class="box">
            <h2 class="text-center">Какие проблемы решает?</h2>
            <ul class="text-left lead">
              <li>
                <span>Сокращает до 50% операционных затрат. Займитесь
                  стратегическим развитием бизнеса.</span>
              </li>
              <li>
                <span>Позволяет увеличить прибыль салона на 30-50%.</span>
              </li>
              <li>
                <span>Устраняет хаос, выстраивает процессы для максимальной
                  эффективности работы бизнеса.</span>
              </li>
              <li>
                <span>Полный контроль сотрудников и процессов компании.</span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </section>

  <section id="features" class="comments gray-section" style="margin-top: 0">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h4>Всё что нужно для эффективной работы салона штор</h4>
          <h4>
            <span class="navy">Не тратьте деньги и время на разработку собственных систем и
              решений. Мы всё уже сделали за вас</span>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 features-block">
          <div class="bubble">
            <h4>Быстрый автоматизированный расчёт заказов</h4>
          </div>
        </div>

        <div class="col-lg-4 features-block">
          <div class="bubble">
            <h4>Сохранение всех данных о клиенте и его заказах</h4>
          </div>
        </div>

        <div class="col-lg-4 features-block">
          <div class="bubble">
            <h4>Автоматический закуп тканей и фурнитуры для заказов</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 features-block">
          <div class="bubble">
            <!-- <h4>Актуальные прайс-листы поставщиков</h4> -->
            <h4>Подготовка всей документации по заказу</h4>
          </div>
        </div>

        <div class="col-lg-4 features-block">
          <div class="bubble">
            <h4>Точный расчёт сроков производства изделий</h4>
          </div>
        </div>

        <div class="col-lg-4 features-block">
          <div class="bubble">
            <h4>Контроль процессов и сотрудников</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 features-block">
          <div class="bubble">
            <h4>
              Автоматический финансовый учёт. Детальная аналитика салона штор
            </h4>
          </div>
        </div>

        <div class="col-lg-4 features-block">
          <div class="bubble">
            <h4>Управление производством. Технологические карты изделий</h4>
          </div>
        </div>

        <div class="col-lg-4 features-block">
          <div class="bubble">
            <h4>
              Расчёт зарплат сотрудников по KPI, по продажам и оказанным услугам
            </h4>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container features">
    <div class="row features-block">
      <div class="col-lg-12 text-center">
        <div class="navy-line"></div>
        <h1>Помощник ведения успешного текстильного бизнеса</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 features-text wow fadeInLeft">
        <h2>Единая база клиентов</h2>
        <p>
          <i class="fa fa-check-circle check-element"></i>Создавайте единую базу
          клиентов с базой знаний взаимодействия с каждым клиентом<br />
          <i class="fa fa-check-circle check-element"></i>Физические и
          юридические лица. Архитекторы и дизайн студии<br />
          <i class="fa fa-check-circle check-element"></i>Планируйте выезды
          дизайнеров, замеры, звонки и встречи с салонах<br />
          <i class="fa fa-check-circle check-element"></i>Поздравляйте клиентов
          с днём рождения и повышайте лояльность<br />
          <i class="fa fa-check-circle check-element"></i>Сохраняйте дизайн
          проекты, замеры и другие документы<br />
          <i class="fa fa-check-circle check-element"></i>Быстро находите
          проекты и заказы клиентов<br />
        </p>
      </div>
      <div class="col-lg-6 text-right wow fadeInRight">
        <img src="img/sc2.png" alt="dashboard" class="img-responsive pull-right" />
      </div>
    </div>
    <div class="row features-block">
      <div class="col-lg-6 wow fadeInLeft">
        <img src="img/sc1.png" alt="dashboard" class="img-responsive" />
      </div>
      <div class="col-lg-6 features-text wow fadeInRight">
        <h2>Создание проектов и ведение заказов</h2>
        <p>
          <i class="fa fa-check-circle check-element"></i>Быстро расчитывайте
          различные варианты дизайн проектов по готовым шаблонам и типам
          изделий<br />
          <i class="fa fa-check-circle check-element"></i>Создавайте проекты
          расчётов и мгновенно создавайте по ним заказы<br />
          <i class="fa fa-check-circle check-element"></i>Анализируйте
          количество расчитанных проектов. Оперативно выявляйте причини
          несостоявшихся проектов<br />
          <i class="fa fa-check-circle check-element"></i>Подготавливайте
          вариативные коммерческие предложения за несколько кликов<br />
          <i class="fa fa-check-circle check-element"></i>Создавайте все
          необходимые документы: договор, счёт-фактура, акт выполненных работ<br />
          <i class="fa fa-check-circle check-element"></i>Контролируйте сроки
          сдачи заказов, ведите учёт оплат и по заказам, прикладывайте дизайн
          проекты и замеры, формируйте скидки на изделия, назначайте
          исполнителей и ещё многое другое
        </p>
      </div>
    </div>
    <div class="row features-block">
      <div class="col-lg-6 features-text wow fadeInLeft">
        <h2>Автоматизированный закуп тканей и фурнитуры</h2>
        <p>
          <i class="fa fa-check-circle check-element"></i>С легкостью формируйте
          заявки для поставщиков на закуп тканей и фурнитуры<br />
          <i class="fa fa-check-circle check-element"></i>Контролируйте оплаты,
          плановые и фактические даты поступления товаров<br />
          <i class="fa fa-check-circle check-element"></i>Вовремя реагируйте на
          заявки, по которым ткани не успевают прийти вовремя<br />
          <i class="fa fa-check-circle check-element"></i>Подготавливайте
          вариативные коммерческие предложения за несколько кликов<br />
          <i class="fa fa-check-circle check-element"></i>Контролируйте прибыль
          по каждой позиции тканей или фурнитуры<br />
          <i class="fa fa-check-circle check-element"></i>Цех всегда увидит
          сообщение, которое ему передал кладовщик<br />
          <i class="fa fa-check-circle check-element"></i>Передавайте в цех
          скопмлектованные заказы
        </p>
      </div>
      <div class="col-lg-6 text-right wow fadeInRight">
        <img src="img/sc3.png" alt="dashboard" class="img-responsive pull-right" />
      </div>
    </div>
    <div class="row features-block">
      <div class="col-lg-6 wow fadeInLeft">
        <img src="img/sc4.png" alt="dashboard" class="img-responsive" />
      </div>
      <div class="col-lg-6 features-text wow fadeInRight">
        <h2>График производства и контроль выполнения заказов</h2>
        <p>
          <i class="fa fa-check-circle check-element"></i>Дизайнер передает
          наряд на производство через нашу систему. Ни один наряд не
          потеряется<br />
          <i class="fa fa-check-circle check-element"></i>Смотрите календарь
          загрузки каждой швеи и чётко понимайте, когда изготовят ваш заказ<br />
          <i class="fa fa-check-circle check-element"></i>Назначайте свободных
          швей на изделия или заказы, чтобы быстрее сдать заказ и получить за
          него оплату<br />
          <i class="fa fa-check-circle check-element"></i>Контролируйте и
          планируйте загрузку цеха<br />
          <i class="fa fa-check-circle check-element"></i>Технолог всегда видит
          заказы в работе и заказы, которые скоро поступят в работу<br />
          <i class="fa fa-check-circle check-element"></i>Модуль работы с
          производством позволяет значительно сократить время производства
          изделий и время сдачи заказов<br />
        </p>
      </div>
    </div>
    <div class="row features-block">
      <div class="col-lg-6 features-text wow fadeInLeft">
        <h2>Рабочий календарь сотрудника</h2>
        <p>
          <i class="fa fa-check-circle check-element"></i>Контролируйте, чем
          заняты ваши сотрудники<br />
          <i class="fa fa-check-circle check-element"></i>Каждый сотрудник с
          легкостью может увидеть свою загрузку и спланировать работу<br />
          <i class="fa fa-check-circle check-element"></i>Планируйте свою
          работу, исходя из загрузки других сотрудников. Всегда видно когда
          совбоден монтажник карнизов или водитель<br />
          <i class="fa fa-check-circle check-element"></i>Назначайте задачи на
          сотрудников. Задачи отображаются в рабочем календаре<br />
          <i class="fa fa-check-circle check-element"></i>Ни одна встреча или
          звонок не будут пропущены. Наша система отправит напоминание о
          необходимом действии
        </p>
      </div>
      <div class="col-lg-6 text-right wow fadeInRight">
        <img src="img/sc5.png" alt="dashboard" class="img-responsive pull-right" />
      </div>
    </div>
  </section>

  <section class="timeline gray-section">
    <div class="container">
      <!-- <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Мы постоянно развиваемся и уже совсем скоро в нашем сервисе</h1>
        </div>
      </div> -->
      <!-- <div class="row features-block">
        <div class="col-lg-12">
          <div
            id="vertical-timeline"
            class="vertical-container light-timeline center-orientation"
          >
            <div class="vertical-timeline-block">
              <div class="vertical-timeline-icon navy-bg">
                <i class="fa fa-money"></i>
              </div>

              <div class="vertical-timeline-content">
                <h2>Расчёт зарплаты</h2>
                <p>
                  Зарплата расчитывается для всей команды в автомотическом
                  режиме. Расчёт настраивается для каждого сотрудника
                  индивидуально, на основе плана продаж, выполненных услуг, KPI
                  и т.д, с учётом производственного календаря. Каждый сотрудник
                  видит свою текущую зарлату старается сделать максимальный план
                  чтобы заработать больше.
                </p>

                <span class="vertical-date"> Июль 2022 </span>
              </div>
            </div>

            <div class="vertical-timeline-block">
              <div class="vertical-timeline-icon navy-bg">
                <i class="fa fa-briefcase"></i>
              </div>

              <div class="vertical-timeline-content">
                <h2>Прайсы поставщиков</h2>
                <p>
                  Всегда актуальные прайсы поставщиков, с учётом валют и скидок
                  салонов. Каждый салон сможет настроить свою наценку на все
                  товары либо на отдельные коллекции поставщиков.
                </p>

                <span class="vertical-date"> Август 2022 </span>
              </div>
            </div>

            <div class="vertical-timeline-block">
              <div class="vertical-timeline-icon navy-bg">
                <i class="fa fa-pie-chart"></i>
              </div>

              <div class="vertical-timeline-content">
                <h2>Детальная аналитика бизнеса</h2>
                <p>
                  Анализируйте работу салона на каждом этапе и меняйте подходы в
                  работе для получения максимальной прибыли. Расчёт
                  рентабильности и маржинальности бизнеса. Статистика
                  поступлений и затрат. Воронки продаж. Расчёт эффективности
                  работы сотрудников.
                </p>
                <span class="vertical-date"> Сентябрь 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Но это ещё не все</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center">
          <h4>
            <span class="navy">Мы развиваем нашу систему совместно со всеми участиками проекта
              CURS24, вырабатывая и внедряя наиболее эффективные решения для
              управления шторным бизнесом. Мы готовы реализовать нужный вам
              функционал абсолютно бесплатно, при условии что он поможет
              остальным салонам стать лучше</span>
          </h4>
        </div>
      </div>
    </div>
  </section>

  <section id="pricing" class="pricing">
    <div class="container">
      <div class="row m-b-lg">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Тарифы</h1>
          <h3>CURS24 стоит меньше, чем беспорядок в Excel, упущенные заказы и финансовые потери</h3>
          <h4>Первый платёж не менее чем за 3 месяца.</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 wow zoomIn animated" style="visibility: visible">
          <ul class="pricing-plan list-unstyled selected">
            <li class="pricing-title">Старт</li>
            <li class="pricing-price">
              <div class="ts-price" style="display: flex">
                <span class="price-t">1200</span>
                <div class="r_m">
                  <span class="rouble fw-600">₽ в месяц при </span>
                  <span class="fw-300 fs-16">оплате за год</span>
                </div>
              </div>
              <div>1500 ₽ при оплате помесячно</div>
            </li>
            <li class="pricing-price pricing-price-users">
              <span>Одно юрлицо</span> <br />
              <span>Один пользователь</span> <br />
              <span>Объем хранилища: 3 Гб</span>
            </li>
            <li style="height: 250px">
              <p style="text-align: left">
                <i class="fa fa-check-circle check-element"></i>База клиентов<br />
                <i class="fa fa-check-circle check-element"></i>Расчёт
                проектов<br />
                <i class="fa fa-check-circle check-element"></i>Ведение
                заказов<br />
                <i class="fa fa-check-circle check-element"></i>Календарь планирования
                <br />
                <i class="fa fa-check-circle check-element"></i>Формирование документов<br />
                <i class="fa fa-check-circle check-element"></i>Комплектация заказов
                <br />
                <i class="fa fa-check-circle check-element"></i>Шаблоны документов
                <br />
                <i class="fa fa-check-circle check-element"></i>Производство
                <br />
                <i class="fa fa-check-circle check-element"></i>Автоматические расчёты изделий
                <br />
                <i class="fa fa-check-circle check-element"></i>Отчёты и аналитика
                <br />
                <i class="fa fa-check-circle check-element"></i>Подрядные сотрудники
              </p>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 wow zoomIn animated" style="visibility: visible">
          <ul class="pricing-plan list-unstyled selected">
            <li class="pricing-title">Базовый</li>
            <li class="pricing-price">
              <div class="ts-price" style="display: flex">
                <span class="price-t">{{ baseYear }}</span>
                <div class="r_m">
                  <span class="rouble fw-600">₽ в месяц при </span>
                  <span class="fw-300 fs-16">оплате за год</span>
                </div>
              </div>              
              <div>{{ baseMonth }} ₽ при оплате помесячно</div>
            </li>
            <li class="pricing-price pricing-price-users">
              <span>Одно юрлицо</span> <br />
              <span>Один салон</span> <br />
              <table class="pricing-price-table">                
                <tr>
                  <td>Cотрудников:</td>
                  <td>
                    <div style="white-space:nowrap; text-align: right; margin-top: 3px;">
                      <button type="button" class="btn btn-success btn-sm" style="margin-right: 10px; padding: 2px 7px;"
                        @click="baseUsers -= 1" v-if="baseUsers > 4">-</button>
                      <span>{{ baseUsers }}</span>
                      <button type="button" class="btn btn-success btn-sm" style="margin-left: 10px; padding: 2px 7px;"
                        @click="baseUsers += 1">+</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Объем хранилища:</td>
                  <td>5 Гб</td>
                </tr>
              </table>
            </li>
            <li style="height: 250px">
              <p style="text-align: left" class="pricing-price">
                <span>Все что в тарифе "Старт", плюс:</span><br />                
                <i class="fa fa-check-circle check-element"></i>Несколько вариантов договоров и коммерческих предложений<br />
                <i class="fa fa-check-circle check-element"></i>"Живое" коммерческое предложение по ссылке<br />
                <i class="fa fa-check-circle check-element"></i>Онлайн оплата по СБП<br />
                <i class="fa fa-check-circle check-element"></i>Расчёт зарплаты
                <br />
                <i class="fa fa-check-circle check-element"></i>Складской учёт
              </p>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 wow zoomIn animated" style="visibility: visible">

          <ul class="pricing-plan list-unstyled selected">
            <div><span class="label label-warning"
                style="position: absolute; right: 2px; top:4px;font-size:inherit">ХИТ</span></div>
            <li class="pricing-title">
              <strong>Бизнес</strong>
            </li>
            <li class="pricing-price">
              <div class="ts-price" style="display: flex">
                <span class="price-t">{{ profYear }}</span>
                <div class="r_m">
                  <span class="rouble fw-600">₽ в месяц при </span>
                  <span class="fw-300 fs-16">оплате за год</span>
                </div>
              </div>
              <div>{{ profMonth }}₽ при оплате помесячно</div>
            </li>
            <li class="pricing-price pricing-price-users">
              <span>Несколько юрлиц</span> <br />
              <span>Несколько салонов</span> <br />
              <table class="pricing-price-table">                
                <tr>
                  <td>Cотрудников:</td>
                  <td>
                    <div style="white-space:nowrap;  text-align: right; margin-top: 3px;">
                      <button type="button" class="btn btn-success btn-sm" style="margin-right: 10px; padding: 2px 7px;"
                        @click="profUsers -= 1" v-if="profUsers > 8">-</button>
                      <span>{{ profUsers }}</span>
                      <button type="button" class="btn btn-success btn-sm" style="margin-left: 10px; padding: 2px 7px;"
                        @click="profUsers += 1">+</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Объем хранилища:</td>
                  <td>50 Гб</td>
                </tr>
              </table>
            </li>
            <li style="height: 250px">
              <p style="text-align: left" class="pricing-price">
                <span>Все что в тарифе "Базовый", плюс:</span><br />
                <i class="fa fa-check-circle check-element"></i>Не ограниченное количество юридических лиц<br />
                <i class="fa fa-check-circle check-element"></i>Возможность загрузки видео для "Живого" коммерческого предложения<br />
                <!-- <i class="fa fa-check-circle check-element"></i>Шаблоны нарядов для цеха<br /> -->
                <i class="fa fa-check-circle check-element"></i>Интеграция с вашим сайтом для загрузки лидов<br />
                <i class="fa fa-check-circle check-element"></i>Приоритетная поддержка
              </p>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 wow zoomIn animated" style="visibility: visible">
          <ul class="pricing-plan list-unstyled selected">
            <li class="pricing-title">Профи
              <!-- <div style="line-height: 18px; font-size: small;">
                Для маленькой, но амбициозной команды</div> -->
            </li>
            <li class="pricing-price">
              <div class="ts-price" style="display: flex">
                <span class="price-t">288 000 ₽</span>
              </div>
              <div>Единаразово</div>
            </li>
            <li class="pricing-price pricing-price-users">
              <span>Пользователей не ограничено</span> <br />
              <span>Объем хранилища: не ограничено</span> <br />
            </li>
            <li style="height: 250px">
              <p style="text-align: left" class="pricing-price">
                <span>Все что в тарифе "Бизнес", плюс:</span><br />
                <i class="fa fa-check-circle check-element"></i>Доступ на своем домене<br />
                <i class="fa fa-check-circle check-element"></i>Размещение на серверах заказчика
              </p>
              <p style="margin-top: 50px;">
                <span >Услуги по разворачиванию и настройке сервера оплачиваются отдельно</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="timeline gray-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Простое и быстрое внедрение</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center">
          <h4>
            <span class="navy">Мы бесплатно заполним основные справочники, необходимые для начала работы</span>
          </h4>
          <h4>
            <span class="navy">Внедрение занимает около 3-х дней. Уже через неделю ваши сотрудники свободно будут работать в системе</span>
          </h4>
        </div>
      </div>
    </div>
  </section>

  <section id="pricing" class="pricing">
    <div class="container padding-sign">
      <div class="row text-center">
        <div class="col-md-12 wow fadeIn d-flex">
          <div class="box sign-content">
            <div class="row">
              <div class="col-md-4">
                <img height="200" src="img/bussiness-up.svg" />
              </div>
              <div class="col-md-8">
                <h2 class="text-center">
                  Начните зарабатывать больше используя CURS24
                </h2>
                <h3>
                  Бесплатный тестовый доступ ко всем возможностям системы на 14 дней.
                </h3>
                <a class="btn btn-green large-button btn-consultation" href="#"
                  btn-type="Консультация внизу страницы" role="button" style="margin-right: 10px">Бесплатная
                  консультация</a>

                <a class="btn btn-primary large-button btn-register" btn-type="Заявка на регистрацию внизу лендинга"
                  href="#" role="button">Заявка на подключение</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="modal inmodal" id="modal-consultation" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content animated fadeIn">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
          </button>
          <h4 class="modal-title">Запись на бесплатную консультацию</h4>
        </div>
        <div class="modal-body text-center">
          <div id="user-data-consultation">
            <p class="text-center">
              <strong>Введите свое имя и номер телефона, мы свяжемся с Вами и ответим
                на все вопросы</strong>
            </p>
            <div class="form-group" style="padding-top: 10px">
              <input id="name" placeholder="Ваше имя" class="form-control" style="width: 250px; display: inline-flex" />
              <div id="name-validation" class="validation-error" style="display: none"></div>
            </div>
            <div class="form-group">
              <input id="phone" placeholder="Телефон" class="form-control" style="width: 250px; display: inline-flex" />
              <div id="phone-validation" class="validation-error" style="display: none"></div>
            </div>
            <div>
              <button type="button" id="sendRequestToFreeConsultation" class="btn btn-primary large-button"
                style="width: 250px">
                Отправить
              </button>
              <p>
                <small>Нажимая кнопку «Отправить», я даю свое согласие на обработку
                  моих персональных данных</small>
              </p>
            </div>
          </div>
          <div id="success-data-consultation" style="display: none">
            <h3>
              Данные успешно отправлены, мы свяжемся с вами в ближайшее время.
            </h3>
            <button type="button" id="closeFreeConsultation" class="btn btn-primary large-button" style="width: 250px">
              Закрыть
            </button>
          </div>
        </div>
        <div class="modal-footer text-center">
          <div class="text-center">
            <strong>Или просто позвоните либо напишите нам в Whatsapp/Telegram на
              номер</strong>
            <br />
            <h1>+7 (915) 154-00-24</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal inmodal" id="modal-registration" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content animated fadeIn">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
          </button>
          <h4 class="modal-title">Заявка на подключение</h4>
        </div>
        <div class="modal-body">
          <div id="user-data-registration">
            <p class="text-center">
              <strong>После получения заявки мы свяжемся с Вами и запросим дополнительно карточку предприятия для заведения профиля компании в системе</strong>
            </p>
            <div class="form-group" style="padding-top: 10px">
              <label>Ваши имя и фамилия</label>
              <input id="name" class="form-control" />
              <div id="name-validation" class="validation-error" style="display: none"></div>
            </div>
            <div class="form-group">
              <label>Ваш телефон (только уведомления по делу, никаких
                рассылок)</label>
              <input id="phone" class="form-control" />
              <div id="phone-validation" class="validation-error" style="display: none"></div>
            </div>
            <div class="form-group">
              <label>Название вашего салона или ссылка на любую вашу соцсеть</label>
              <input id="salon" class="form-control" />
              <div id="salon-validation" class="validation-error" style="display: none"></div>
            </div>
            <div class="form-group">
              <label>Ваш e-mail</label>
              <input id="email" type="email" class="form-control" />
              <div id="email-validation" class="validation-error" style="display: none"></div>
            </div>
            <div class="form-group">
              <label>Расскажите о своем бизнесе и какие задачи хотите решить</label>
              <textarea id="tariff" rows="5" wrap="soft" class="form-control"></textarea>
            </div>
            <div style="text-align: center">
              <button type="button" id="sendRequestRegistration" class="btn btn-primary large-button"
                style="width: 300px">
                Отправить заявку на подключение
              </button>
              <p>
                <small>Нажимая кнопку «Отправить», я даю свое согласие на обработку
                  моих персональных данных</small>
              </p>
            </div>
          </div>
          <div id="success-data-registration" style="display: none; text-align: center">
            <h3>
              Заявка успешно отправлена!
            </h3>
            <button type="button" id="closeFreeRegistration" class="btn btn-primary large-button" style="width: 250px">
              Закрыть
            </button>
          </div>
        </div>
        <div class="modal-footer text-center">
          <div class="text-center">
            <strong>Если возникли вопросы позвоните либо напишите нам в
              Whatsapp/Telegram</strong>
            <br />
            <h1>+7 (915) 154-00-24</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  data() {
    return {      
      baseUsers: 4,      
      profUsers: 8,
    };
  },
  computed: {
    baseMonth() {
      return 2300 + this.baseUsers * 300;
    },    
    baseYear() {
      return this.baseMonth - this.baseMonth * 0.2;
    },
    profMonth() {
      return 3600 + this.profUsers * 300;
    },
    profYear() {
      return this.profMonth - this.profMonth * 0.2;
    }
  },
};
</script>
